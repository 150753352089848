"use client"

import React from "react"

export default function InterviewAnswerGeneratorSections() {
    const [openFaqIndex, setOpenFaqIndex] = React.useState(null)

    // Reviews data
    const reviews = [
        {
            name: "Michael Chen",
            role: "Software Engineer @ Amazon",
            rating: 5,
            comment:
                "This tool saved me during my tech interviews! The answers were detailed and technical enough to impress the interviewer while still sounding natural.",
        },
        {
            name: "Sarah Johnson",
            role: "Marketing Manager @ Spotify",
            rating: 5,
            comment:
                "I used this to prepare for behavioral questions and it gave me perfect frameworks to structure my answers. Landed my dream job!",
        },
        {
            name: "James Rodriguez",
            role: "Product Manager @ Microsoft",
            rating: 4,
            comment:
                "The answer generator helped me prepare for tough questions about my career gap. The responses were thoughtful and helped me frame my experience positively.",
        },
        {
            name: "Emily Wilson",
            role: "Data Scientist @ Netflix",
            rating: 5,
            comment:
                "The AI-generated answers helped me articulate complex technical concepts in a clear, concise way. Definitely worth using before any interview!",
        },
        {
            name: "David Kim",
            role: "UX Designer @ Adobe",
            rating: 5,
            comment:
                "As a designer, I needed help explaining my process and decisions. This tool gave me perfect responses that showcased my skills effectively.",
        },
        {
            name: "Lisa Thompson",
            role: "Financial Analyst @ Goldman Sachs",
            rating: 4,
            comment:
                "The industry-specific answers were impressive. It understood finance terminology and helped me prepare for technical questions.",
        },
        {
            name: "Robert Garcia",
            role: "Sales Director @ Salesforce",
            rating: 5,
            comment:
                "This tool helped me quantify my achievements and present them confidently. The answers were persuasive and results-focused.",
        },
        {
            name: "Jennifer Lee",
            role: "HR Manager @ LinkedIn",
            rating: 5,
            comment:
                "Even as an HR professional who conducts interviews, I found this tool invaluable for my own job search. Highly recommended!",
        },
    ]

    // FAQs data
    const faqs = [
        {
            question: "What is the AI Interview Answer Generator?",
            answer: "It's an AI-powered tool that helps job seekers generate professional, tailored responses to interview questions based on their job title and the specific job description.",
        },
        {
            question: "How should I use the generated answers?",
            answer: "Use the generated answers as a starting point. Personalize them with your own experiences and examples to make them authentic. Practice delivering them naturally rather than memorizing word-for-word.",
        },
        {
            question: "Is it free to use or do I need a subscription?",
            answer: "You can use the core features completely free. No subscription is required to generate multiple answers for your interview preparation.",
        },
        {
            question: "Can I use it for technical interviews?",
            answer: "Yes! Our tool can generate answers for technical questions across various fields including software engineering, data science, finance, and more. Just specify your role and the question.",
        },
        {
            question: "How do I make the answers sound like me?",
            answer: "After generating an answer, edit it to include your personal experiences, use your natural speaking style, and adjust the tone to match your personality. This customization makes the answer uniquely yours.",
        },
        {
            question: "Can I save my generated answers for later?",
            answer: "Yes, you can copy and save all generated answers to your own documents. We recommend creating a personal interview preparation document with all your customized responses.",
        },
    ]

    return (
        <div
            style={{
                fontFamily: "Inter, sans-serif",
                maxWidth: "100%",
                overflow: "hidden",
                border: "1px solid #e5e7eb",
                borderRadius: "8px",
            }}
        >
            {/* How to Use Section */}
            <div style={{ textAlign: "center", padding: "20px 15px" }}>
                <h2
                    style={{
                        color: "#4f46e5",
                        fontSize: "24px",
                        marginBottom: "20px",
                        fontWeight: "600",
                    }}
                >
                    How to Use Our Interview Answer Generator?
                </h2>

                <div
                    style={{
                        maxWidth: "800px",
                        margin: "0 auto",
                        textAlign: "left",
                    }}
                >
                    {/* Step 1 */}
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                        <div
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "#4f46e5",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "18px",
                                fontWeight: "bold",
                                flexShrink: 0,
                            }}
                        >
                            1
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                            <h3
                                style={{
                                    margin: "0 0 5px 0",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                }}
                            >
                                Enter Your Job Title
                            </h3>
                            <p
                                style={{
                                    margin: 0,
                                    color: "#4b5563",
                                    fontSize: "14px",
                                }}
                            >
                                Specify the role you're applying for to get
                                tailored, role-specific interview answers.
                            </p>
                        </div>
                    </div>

                    {/* Step 2 */}
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                        <div
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "#4f46e5",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "18px",
                                fontWeight: "bold",
                                flexShrink: 0,
                            }}
                        >
                            2
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                            <h3
                                style={{
                                    margin: "0 0 5px 0",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                }}
                            >
                                Paste the Job Description
                            </h3>
                            <p
                                style={{
                                    margin: 0,
                                    color: "#4b5563",
                                    fontSize: "14px",
                                }}
                            >
                                Add the job description to ensure your answers
                                align perfectly with the company's requirements.
                            </p>
                        </div>
                    </div>

                    {/* Step 3 */}
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                        <div
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "#4f46e5",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "18px",
                                fontWeight: "bold",
                                flexShrink: 0,
                            }}
                        >
                            3
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                            <h3
                                style={{
                                    margin: "0 0 5px 0",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                }}
                            >
                                Enter the Interview Question
                            </h3>
                            <p
                                style={{
                                    margin: 0,
                                    color: "#4b5563",
                                    fontSize: "14px",
                                }}
                            >
                                Type or paste the specific interview question
                                you need help answering.
                            </p>
                        </div>
                    </div>

                    {/* Step 4 */}
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                        <div
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "#4f46e5",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "18px",
                                fontWeight: "bold",
                                flexShrink: 0,
                            }}
                        >
                            4
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                            <h3
                                style={{
                                    margin: "0 0 5px 0",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                }}
                            >
                                Generate Your Answer
                            </h3>
                            <p
                                style={{
                                    margin: 0,
                                    color: "#4b5563",
                                    fontSize: "14px",
                                }}
                            >
                                Click the "Generate Answer" button and receive a
                                professional, tailored response in seconds.
                            </p>
                        </div>
                    </div>

                    {/* Step 5 */}
                    <div style={{ display: "flex", marginBottom: "0" }}>
                        <div
                            style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                backgroundColor: "#4f46e5",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "18px",
                                fontWeight: "bold",
                                flexShrink: 0,
                            }}
                        >
                            5
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                            <h3
                                style={{
                                    margin: "0 0 5px 0",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                }}
                            >
                                Review and Customize
                            </h3>
                            <p
                                style={{
                                    margin: 0,
                                    color: "#4b5563",
                                    fontSize: "14px",
                                }}
                            >
                                Copy the answer and personalize it with your own
                                experiences for an authentic response.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Features Section */}
            <div
                style={{
                    backgroundColor: "#f9fafb",
                    padding: "20px 15px",
                    textAlign: "center",
                }}
            >
                <h2
                    style={{
                        fontSize: "24px",
                        marginBottom: "10px",
                        fontWeight: "600",
                    }}
                >
                    Why Our AI Answer Generator?
                </h2>
                <p
                    style={{
                        color: "#4b5563",
                        maxWidth: "600px",
                        margin: "0 auto 20px",
                        fontSize: "14px",
                    }}
                >
                    Discover the powerful benefits that make our tool essential
                    for acing your next interview.
                </p>

                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns:
                            "repeat(auto-fit, minmax(250px, 1fr))",
                        gap: "15px",
                        maxWidth: "1000px",
                        margin: "0 auto",
                    }}
                >
                    {/* Feature 1 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "20px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#ede9fe",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "10px",
                                color: "#4f46e5",
                            }}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path d="M9.5 2A2.5 2.5 0 0 1 12 4.5v15a2.5 2.5 0 0 1-4.96.44 2.5 2.5 0 0 1-2.96-3.08 3 3 0 0 1-.34-5.58 2.5 2.5 0 0 1 1.32-4.24 2.5 2.5 0 0 1 1.98-3A2.5 2.5 0 0 1 9.5 2Z" />
                                <path d="M14.5 2A2.5 2.5 0 0 0 12 4.5v15a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 14.5 2Z" />
                            </svg>
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                margin: "0 0 5px 0",
                                fontWeight: "600",
                            }}
                        >
                            Advanced AI Engine
                        </h3>
                        <p
                            style={{
                                color: "#4b5563",
                                fontSize: "14px",
                                margin: 0,
                            }}
                        >
                            Powered by GPT-4o to create human-like,
                            natural-sounding interview answers.
                        </p>
                    </div>

                    {/* Feature 2 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "20px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#ede9fe",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "10px",
                                color: "#4f46e5",
                            }}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
                            </svg>
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                margin: "0 0 5px 0",
                                fontWeight: "600",
                            }}
                        >
                            Instant Responses
                        </h3>
                        <p
                            style={{
                                color: "#4b5563",
                                fontSize: "14px",
                                margin: 0,
                            }}
                        >
                            Generate professional answers in under 30
                            seconds—perfect for last-minute preparation.
                        </p>
                    </div>

                    {/* Feature 3 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "20px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#ede9fe",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "10px",
                                color: "#4f46e5",
                            }}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <circle cx="12" cy="12" r="10" />
                                <circle cx="12" cy="12" r="6" />
                                <circle cx="12" cy="12" r="2" />
                            </svg>
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                margin: "0 0 5px 0",
                                fontWeight: "600",
                            }}
                        >
                            Job-Specific Answers
                        </h3>
                        <p
                            style={{
                                color: "#4b5563",
                                fontSize: "14px",
                                margin: 0,
                            }}
                        >
                            Tailored responses based on your job title and the
                            actual job description for maximum relevance.
                        </p>
                    </div>

                    {/* Feature 4 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "20px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#ede9fe",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "10px",
                                color: "#4f46e5",
                            }}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <circle cx="12" cy="12" r="10" />
                                <polyline points="12 6 12 12 16 14" />
                            </svg>
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                margin: "0 0 5px 0",
                                fontWeight: "600",
                            }}
                        >
                            Time-Saving
                        </h3>
                        <p
                            style={{
                                color: "#4b5563",
                                fontSize: "14px",
                                margin: 0,
                            }}
                        >
                            Skip hours of brainstorming and get well-structured,
                            compelling answers immediately.
                        </p>
                    </div>

                    {/* Feature 5 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "20px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#ede9fe",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "10px",
                                color: "#4f46e5",
                            }}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <circle cx="12" cy="12" r="10" />
                                <path d="M8 14s1.5 2 4 2 4-2 4-2" />
                                <line x1="9" y1="9" x2="9.01" y2="9" />
                                <line x1="15" y1="9" x2="15.01" y2="9" />
                            </svg>
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                margin: "0 0 5px 0",
                                fontWeight: "600",
                            }}
                        >
                            Natural Tone
                        </h3>
                        <p
                            style={{
                                color: "#4b5563",
                                fontSize: "14px",
                                margin: 0,
                            }}
                        >
                            Human-like responses that sound authentic, not
                            robotic or overly formal.
                        </p>
                    </div>

                    {/* Feature 6 */}
                    <div
                        style={{
                            backgroundColor: "white",
                            borderRadius: "8px",
                            padding: "20px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                backgroundColor: "#ede9fe",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "10px",
                                color: "#4f46e5",
                            }}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                <circle cx="9" cy="7" r="4" />
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                            </svg>
                        </div>
                        <h3
                            style={{
                                fontSize: "18px",
                                margin: "0 0 5px 0",
                                fontWeight: "600",
                            }}
                        >
                            Confidence Booster
                        </h3>
                        <p
                            style={{
                                color: "#4b5563",
                                fontSize: "14px",
                                margin: 0,
                            }}
                        >
                            Enter interviews with polished, professional answers
                            that help you articulate your thoughts clearly.
                        </p>
                    </div>
                </div>
            </div>

            {/* Reviews Section */}
            <div style={{ padding: "20px 15px", textAlign: "center" }}>
                <h2
                    style={{
                        color: "#4f46e5",
                        fontSize: "24px",
                        marginBottom: "5px",
                        fontWeight: "600",
                    }}
                >
                    20,000+ Job Seekers Already Using Interview Sidekick
                </h2>
                <p
                    style={{
                        color: "#4b5563",
                        marginBottom: "20px",
                        fontSize: "14px",
                    }}
                >
                    See what they have to say about our Answer Generator.
                </p>

                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns:
                            "repeat(auto-fit, minmax(280px, 1fr))",
                        gap: "15px",
                        maxWidth: "1000px",
                        margin: "0 auto",
                    }}
                >
                    {/* Map through all 8 reviews */}
                    {reviews.map((review, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundColor: "#f9fafb",
                                borderRadius: "8px",
                                padding: "15px",
                                textAlign: "left",
                            }}
                        >
                            <h3
                                style={{
                                    color: "#4f46e5",
                                    fontSize: "16px",
                                    margin: "0 0 2px 0",
                                    fontWeight: "600",
                                }}
                            >
                                {review.name}
                            </h3>
                            <p
                                style={{
                                    color: "#6b7280",
                                    fontSize: "12px",
                                    margin: "0 0 5px 0",
                                }}
                            >
                                {review.role}
                            </p>
                            <div
                                style={{
                                    color: "#f59e0b",
                                    marginBottom: "5px",
                                }}
                            >
                                {Array(5)
                                    .fill(0)
                                    .map((_, i) => (
                                        <span key={i}>
                                            {i < review.rating ? "★" : "☆"}
                                        </span>
                                    ))}
                            </div>
                            <p
                                style={{
                                    color: "#374151",
                                    fontSize: "14px",
                                    margin: 0,
                                }}
                            >
                                {review.comment}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

            {/* FAQ Section */}
            <div
                style={{
                    backgroundColor: "#f9fafb",
                    padding: "20px 15px",
                    textAlign: "center",
                }}
            >
                <h2
                    style={{
                        color: "#4f46e5",
                        fontSize: "24px",
                        marginBottom: "5px",
                        fontWeight: "600",
                    }}
                >
                    Frequently Asked Questions
                </h2>
                <p
                    style={{
                        color: "#4b5563",
                        marginBottom: "20px",
                        fontSize: "14px",
                    }}
                >
                    Everything you need to know about our AI Interview Answer
                    Generator
                </p>

                <div style={{ maxWidth: "800px", margin: "0 auto" }}>
                    {/* Map through all 6 FAQs */}
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundColor: "white",
                                borderRadius: "8px",
                                padding: "15px",
                                marginBottom: "10px",
                                textAlign: "left",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                setOpenFaqIndex(
                                    openFaqIndex === index ? null : index
                                )
                            }
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#4f46e5",
                                            marginRight: "10px",
                                            display: "flex",
                                        }}
                                    >
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <circle cx="12" cy="12" r="10" />
                                            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                            <line
                                                x1="12"
                                                y1="17"
                                                x2="12.01"
                                                y2="17"
                                            />
                                        </svg>
                                    </span>
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {faq.question}
                                    </span>
                                </div>
                                <span
                                    style={{
                                        color: "#4f46e5",
                                        display: "flex",
                                    }}
                                >
                                    {openFaqIndex === index ? (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <polyline points="18 15 12 9 6 15" />
                                        </svg>
                                    ) : (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <polyline points="6 9 12 15 18 9" />
                                        </svg>
                                    )}
                                </span>
                            </div>

                            {openFaqIndex === index && (
                                <p
                                    style={{
                                        marginTop: "10px",
                                        color: "#4b5563",
                                        paddingLeft: "30px",
                                        fontSize: "14px",
                                    }}
                                >
                                    {faq.answer}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Contact Section */}
            <div
                style={{
                    borderTop: "1px solid #e5e7eb",
                    padding: "15px",
                    textAlign: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <p
                        style={{
                            margin: 0,
                            fontSize: "14px",
                            color: "#4b5563",
                        }}
                    >
                        Questions/feedback/concerns? Email me!
                    </p>
                    <p
                        style={{
                            margin: 0,
                            fontSize: "14px",
                            color: "#4b5563",
                        }}
                    >
                        I'll reply as soon as I can
                    </p>
                    <a
                        href="mailto:ben@interviewsidekick.com"
                        style={{
                            display: "inline-block",
                            backgroundColor: "#f3f4f6",
                            padding: "6px 12px",
                            borderRadius: "6px",
                            textDecoration: "none",
                            color: "#4f46e5",
                            fontWeight: "500",
                            fontSize: "14px",
                            marginTop: "5px",
                        }}
                    >
                        ben@interviewsidekick.com
                    </a>
                </div>
            </div>
        </div>
    )
}
